html,
body,
#root {
  height: 100%;
  margin: 0;
}

body {
  background: white;
  font-family: "Nanum Gothic", sans-serif;
}

button {
  background: none;
  border: none;
}

* {
  box-sizing: border-box;
  font: inherit;
  margin: 0;
}
